import { IAccess } from "@/types/access";

import Business from "@/assets/images/services/business.webp";
import FinancniGramotnost from "@/assets/images/services/financni-gramotnost.webp";
import CloseFriends from "@/assets/images/services/close-friends.webp";
import KazdodenniVyhledy from "@/assets/images/services/kazdodenni-vyhledy.webp";
import LearnToEarn from "@/assets/images/services/learntoearn.webp";
import SkutecneFungovani from "@/assets/images/services/skutecne-fungovani.webp";
import ZapomenutaHistorie from "@/assets/images/services/zapomenuta-historie.webp";
import CryptonytCapital from "@/assets/images/services/cryptonyt-capital.webp";




export const ACCESS_DATA: IAccess[] = [
    {
        title: "Skutečné Fungování Peněz",
        text: "Privátní texty, ve kterých se dozvíte vše o Minulosti, Přítomnosti a Budoucnosti peněz. Jakmile odhalíte Minulost a Přítomnost, tak se budete schopni připravit na Budoucnost a díky tomu ochránit peníze sebe a kupní sílu své rodiny.",
        url: SkutecneFungovani
    },
    {
        title: "Finanční Gramotnost od A do Z",
        text: "Nedostatečná finanční gramotnost představuje jeden z největších problémů dnešní doby. Z toho důvodu jsme vše srozumitelně sepsali do jedné sekce, ze které pochopíte  všechny základní principy spravování peněz.",
        url: FinancniGramotnost
    },
    {
        title: "Finanční Krize",
        text: "Stojíme si za tím, že jakmile pochopíme minulost, tak můžeme odhadnout budoucnost. Proto jsme sepsali všechny finanční krize od roku 1907 a zároveň jsme je srovnali s dnešní aktuální  situací na finančních trzích.",
        url: ZapomenutaHistorie
    },
    {
        title: "Každodenní tržní výhledy",
        text: "Na trzích nejde jenom o to mít ty správné informace, ale také o to je mít ve správný čas. Proto naše členy vždy ihned informujeme o změnách, které by mohly mít radikální dopad na finanční trhy a jejich portfólia. Například jsme zachránili naše členy před krachem FTX burzy.",
        url: KazdodenniVyhledy
    },
    {
        title: "Learn to Earn",
        text: "Jedinou věc, kterou vám nemůže nikdo sebrat, je to, co máte v hlavě. Z toho důvodu jsme vytvořili vzdělávací kategorii, ve které se dozvíte vše od budování návyků, přes plánování, až po celkový pohled na život.",
        url: LearnToEarn
    },
    {
        type: 2,
        title: "CRYPTONYT CAPITAL",
        text: "Tento produkt je přímo specializován na crypto trh. Naučíte se v něm vše od základů, díky jednoduchým tutoriálům, až po pokročilé věci typu, jak funguje tokenomika. Zároveň v něm najdete naši investiční tezi a zákulisní informace od našich spřátelených VC fondů.",
        url: CryptonytCapital
    },
    {
        type: 3,
        title: "CLOSE FRIENDS",
        text: "Na Instagramu existuje možnost \"blízkých přátel\", která je neveřejná. Budeme tam odpovídat na vaše otázky a zároveň tento kanál bude fungovat jako most pro oznámení novinek a přednostní přístup do investičních příležitostí.",
        url: CloseFriends
    },
    {
        type: 3,
        title: "CRYPTONYT BUSINESS",
        text: "Uvědomujeme si, že je aktuálně spousta lidí na začátku a nemá ještě moc co investovat, a proto jsme vytvořili produkt, ve kterém se naučíte vše od základů businessu, přes budování nabídky a brandu, až po následný prodej a škálování vašeho produktu.",
        url: Business
    },

]


export const ACCESS_DATA_EN: IAccess[] = [
    {
        title: "The Real Functioning of Money",
        text: "Private texts in which you will learn everything about the Past, Present and Future of money. Once you discover the Past and the Present, you will be able to prepare for the Future and protect your and your family's money.",
        url: SkutecneFungovani
    },
    {
        title: "Financial Literacy from A to Z",
        text: "Lack of financial literacy is one of the biggest problems of our time. For this reason, we have simply written everything down in one section, which if you read it, you will understand all the basic principles.",
        url: FinancniGramotnost
    },
    {
        title: "Financial crisis",
        text: "We believe that once we understand the past, we can predict the future. That is why we have written down all the financial crises since 1907 and at the same time compared them with today's current situation on the financial markets.",
        url: ZapomenutaHistorie
    },
    {
        title: "Daily market outlooks",
        text: "In the markets, it's not just about having the right information, it's also about having it at the right time. That is why we always inform our members immediately about changes that could have a radical impact on the financial markets and their portfolios. For example, we saved our members from the collapse of the FTX exchange.",
        url: KazdodenniVyhledy
    },
    {
        title: "Learn to Earn",
        text: "The only thing that can't be taken away from you is what's in your head. That's why we've created an educational section where you'll learn everything from building habits to looking at life.",
        url: LearnToEarn
    },
    {
        type: 2,
        title: "CRYPTONYT CAPITAL",
        text: "This product is directly specialized in crypto. You will learn everything from the basics to advanced things such as how tokenomics works or vesting. At the same time, it will act as an alpha group for the upcoming bull market, along with regular analysis of the cryptocurrency market.",
        url: CryptonytCapital
    },
    {
        type: 3,
        title: "CLOSE FRIENDS",
        text: "There is a \"close friends\" option on Instagram that is not public to the wider public. We have decided that each of our members will have access to our close friends. We will answer your questions there, and at the same time this channel will act as a bridge for the announcement of news and investment opportunities.",
        url: CloseFriends
    },
    {
        type: 3,
        title: "CRYPTONYT BUSINESS",
        text: "We realize that a lot of people are at the beginning and don't have much to invest yet, so we've created a product in which you will learn everything from the basics of business, through building an offer and brand, to the subsequent sale and scaling of your product.",
        url: Business
    },

]
