"use client"
import { URL } from "@/utils/const/url";
import Image from "next/image";
import { Link } from "@/navigation";

import "./Header.scss";
import Button from "@/components/atom/Button/Button";
import { useTranslations } from "next-intl";
import LanguagePicker from "@/components/molecule/LanguagePicker/LanguagePicker";
import LogoText from "@/assets/images/logos/logo-text.svg";


const Header = () => {
  const t = useTranslations("header");

  return (
    <div className="header">
      <div className="container">
        <div className="header-content">
          <div className={"header-content-left"}>
            <Link href={URL.HOMEPAGE} className="header-content-logo">
              <Image
                src={LogoText}
                width={188}
                height={58}
                alt="Cryptonyt Logo"
              />
            </Link>
          </div>
          <div className={"header-content-right"}>
            <LanguagePicker />
            <Button link={URL.LOGIN} type="ADMINISTRATION_BUTTON">
              {t("button")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
