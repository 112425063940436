"use client";
import { IPrice } from "@/types/price";
import { Link } from "@/navigation";

import "./PriceCard.scss";
import { useRef } from "react";
import { classNames } from "primereact/utils";
import { PAGE_URL } from "@/utils/const/url";
import Button from "@/components/atom/Button/Button";
import {useTranslations} from "next-intl";

interface IProps {
  card_details: IPrice;
  active?: boolean;
  action?: () => void;
  disableEffect?: boolean;
  buttonText?: string;
  inactive?: boolean;
  loading?: boolean;
  isAdministration?: boolean;
}

const PriceCard = ({
  card_details,
  active,
  action,
  disableEffect,
  buttonText,
  inactive,
    loading,
  isAdministration,
}: IProps) => {
  const { title, description, price, duration, points } = card_details;

  const handleHover = () => {
    const priceCards = document.querySelectorAll(".price-card");
    priceCards.forEach((card) => {
      card.classList.remove("active");
    });
  };

  const handleHoverOut = () => {
    const middlePriceCard = document.querySelector(".price-card.middle");
    if (middlePriceCard) {
      middlePriceCard.classList.add("active");
    }
  };

  const t = useTranslations("price-card");

  return (
    <div
      className={classNames("price-card", {
        "active middle": active,
        "no-hover": disableEffect,
        inactive: inactive,
        administration: isAdministration,
      })}
      onMouseEnter={() => !disableEffect && handleHover()}
      onMouseLeave={() => !disableEffect && handleHoverOut()}
    >
      <div className={"price-card-inner"}>
        <div className={"price-card-inner-small"}>
          <div className="price-card-title">{title}</div>
          <div className="price-card-small">{description}</div>
          <div className="price-card-value">
            <span>{price} Kč</span>
            <span>{duration}</span>
          </div>
          <ul>
            {points.map((point, i) => (
              <li key={i} className={"icon-" + point.icon}>
                {point.text}
              </li>
            ))}
          </ul>
          {
            action ? (
                <Button
                    isLoading={loading}
                    type={"ARROW_BUTTON"}
                    onClick={action}
                >
                  Zakoupit členství
                </Button>
            ) : (
                <Link
                    href={PAGE_URL.REGISTER}
                    className={classNames({
                      active: active
                    })}
                >
                  {buttonText ?? t("button")}
                </Link>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
