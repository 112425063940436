import * as React from "react";
const ClockIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={23}
        fill="none"
        viewBox={"0 0 1051 962"}
        {...props}
    >
      <path
          fill="url(#a)"
          fillRule="evenodd"
          d="M550 16c0-8.837 7.163-16 16-16h108c8.837 0 16 7.163 16 16v33c0 8.837-7.163 16-16 16h-30v35.657C870.871 113.11 1051 301.019 1051 531c0 238.035-192.967 431-431.001 431-180.736 0-335.488-111.246-399.519-269H176c-13.255 0-24-10.745-24-24s10.745-24 24-24h200c13.255 0 24 10.745 24 24s-10.745 24-24 24H272.846c61.026 130.551 193.527 221 347.153 221C831.524 914 1003 742.525 1003 531S831.524 148 619.999 148c-152.447 0-284.091 89.066-345.735 218H328c13.255 0 24 10.745 24 24s-10.745 24-24 24H76c-13.255 0-24-10.745-24-24s10.745-24 24-24h145.711C283.573 216.84 426.785 109.944 596 100.657V65h-30c-8.837 0-16-7.163-16-16V16Zm94 263c0-13.255-10.745-24-24-24s-24 10.745-24 24v252c0 13.255 10.745 24 24 24h252c13.255 0 24-10.745 24-24s-10.745-24-24-24H644V279ZM24 507c-13.255 0-24 10.745-24 24s10.745 24 24 24h383c13.255 0 24-10.745 24-24s-10.745-24-24-24H24Z"
          clipRule="evenodd"
      />
      <defs>
        <linearGradient
            id="a"
            x1={1029.75}
            x2={-1.578}
            y1={841.75}
            y2={832.014}
            gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E4B4" />
          <stop offset={1} stopColor="#006CFF" />
        </linearGradient>
      </defs>
    </svg>
);
export default ClockIcon;
