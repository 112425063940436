"use client"
import ClockIcon from "@/components/atom/Icons/ClockIcon";
import DoorIcon from "@/components/atom/Icons/DoorIcon";
import LockIcon from "@/components/atom/Icons/LockIcon";
import ShieldIcon from "@/components/atom/Icons/ShieldIcon";

import "./Benefits.scss";
import BenefitsItem from "./BenefitsItem";
import {useTranslations} from "next-intl";

const Benefits = () => {

  const t = useTranslations("benefits");

  return (
    <div className="benefits">
      <div className="container">
        <div className="benefits-wrap">
          <BenefitsItem IconComponent={ClockIcon} title={t("one")} />
          <BenefitsItem IconComponent={ShieldIcon} title={t("two")} />
          <BenefitsItem IconComponent={DoorIcon} title={t("three")} />
          <BenefitsItem IconComponent={LockIcon} title={t("four")} />
        </div>
      </div>
    </div>
  );
};

export default Benefits;
