import * as React from "react";
const PlusIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={29}
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="M14.5 0a2.538 2.538 0 0 0-2.537 2.538v9.425H2.538a2.538 2.538 0 0 0 0 5.075h9.425v9.424a2.538 2.538 0 0 0 5.075 0v-9.424h9.424a2.538 2.538 0 0 0 0-5.075h-9.424V2.538A2.538 2.538 0 0 0 14.5 0Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="a"
        x1={28.414}
        x2={0.107}
        y1={25.375}
        y2={7.703}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00E4B4" />
        <stop offset={1} stopColor="#085CFF" />
      </linearGradient>
    </defs>
  </svg>
);
export default PlusIcon;
