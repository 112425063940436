import * as React from "react"
const VerifiedIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={1080}
        height={1080}
        viewBox="0 0 810 810"
        {...props}
    >
        <path
            fill="#1a87ef"
            d="m612.617 567.57-40.054 5.625a19.714 19.714 0 0 0-16.418 14.871l-9.543 39.282c-2.7 11.113-14.262 17.601-25.168 14.129L482.91 629.18a19.72 19.72 0 0 0-21.254 6.273l-25.652 31.254c-7.254 8.84-20.492 9.676-28.805 1.813l-29.375-27.786a19.724 19.724 0 0 0-21.867-3.558l-36.684 17.035c-10.37 4.82-22.664-.168-26.742-10.856l-14.398-37.77a19.728 19.728 0 0 0-18.16-12.69l-40.442-.555c-11.445-.156-20.355-9.985-19.383-21.38l3.422-40.276a19.705 19.705 0 0 0-10.855-19.309l-36.192-18.031c-10.242-5.106-14-17.82-8.183-27.668l20.57-34.801a19.705 19.705 0 0 0-1.39-22.102l-24.786-31.949c-7.011-9.039-4.879-22.12 4.645-28.472l33.637-22.434a19.699 19.699 0 0 0 8.34-20.523l-8.458-39.524c-2.394-11.187 5.211-22.05 16.543-23.644l40.051-5.625a19.723 19.723 0 0 0 16.422-14.872l9.543-39.28c2.7-11.11 14.266-17.602 25.16-14.13l38.535 12.301a19.724 19.724 0 0 0 21.25-6.277l25.649-31.254c7.254-8.84 20.496-9.676 28.808-1.813l29.375 27.786a19.735 19.735 0 0 0 21.871 3.562l36.676-17.04c10.38-4.82 22.668.169 26.742 10.856l14.407 37.77a19.712 19.712 0 0 0 18.152 12.691l40.445.555c11.442.16 20.352 9.984 19.38 21.379l-3.423 40.277a19.718 19.718 0 0 0 10.856 19.309l36.195 18.031c10.242 5.106 14 17.824 8.18 27.668l-20.57 34.8a19.705 19.705 0 0 0 1.394 22.102l24.781 31.95c7.012 9.039 4.883 22.12-4.636 28.472l-33.645 22.434a19.71 19.71 0 0 0-8.336 20.523l8.453 39.524c2.395 11.187-5.21 22.05-16.539 23.644"
        />
        <path
            fill="#fff"
            d="M514.973 298.7 351.555 462.015a6.193 6.193 0 0 1-8.758 0l-47.715-47.684c-8.176-8.172-21.43-8.172-29.605 0-8.176 8.172-8.176 21.426 0 29.598l67.425 67.386c7.883 7.875 20.664 7.875 28.547 0l15.34-15.328 167.79-167.695c8.179-8.168 8.179-21.418 0-29.594-8.169-8.168-21.43-8.168-29.606 0"
        />
    </svg>
)
export default VerifiedIcon
