const PointsLine = () => {
  return (
    <div className="points-line">
      <div className="points-line-item">01</div>
      <div className="points-line-item">02</div>
      <div className="points-line-item">03</div>
    </div>
  );
};

export default PointsLine;
