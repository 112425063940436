"use client"
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

import "./HeroSection.scss";
import Button from "@/components/atom/Button/Button";
import Members from "@/components/molecule/Members/Members";
import { URL } from "@/utils/const/url";
import {useTranslations} from "next-intl";
import {useState} from "react";
import {classNames} from "primereact/utils";

const HeroSection = () => {
  const t = useTranslations("hero");

  return (
    <div className="hero">
      <div className="container">
        <div className="hero-content">
          <h1 dangerouslySetInnerHTML={{__html: t.raw('title')}}>
          </h1>
          <p>
            {t("subtitle")}
          </p>
          <div className={"hero-content-video"}>
            <ReactPlayer
              width={"auto"}
              height={"100%"}
              url={"/static/videos/hero.mp4"}
              controls={true}
            />
          </div>
          <div className="hero-content-cta">
            <Button link={URL.REGISTER} type="ARROW_BUTTON">
              {t("button")}
            </Button>
          </div>
          <div className="hero-content-members">
            <Members />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
