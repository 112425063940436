import Image from "next/image";
import StripeLogo from "@/../public/static/images/logos/stripe.png";
import VisaLogo from "@/../public/static/images/logos/visa.png";

import "./PaymentOptions.scss";

const PaymentOptions = () => {
  return (
    <div className="payment-options">
      <div className="payment-options-item">
        <span>Secure Payments</span>
        <div className="payment-options-image">
          <Image
            width={84}
            height={20}
            src={StripeLogo}
            alt="Stripe Logo"
          />
        </div>
      </div>
      <div className="payment-options-item">
        <span>Safe and Secure SSL Encrypted</span>
        <div className="payment-options-image">
          <Image
            width={144}
            height={20}
            src={VisaLogo}
            alt="Stripe Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentOptions;
