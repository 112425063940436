export const URL = Object.freeze({
    HOMEPAGE: "/",
    LOGIN: "/prihlaseni",
    REGISTER: "/registrace",
    FORGOT_PASSWORD: "/zapomenute-heslo",
    FORGOT_PASSWORD_RESET: "/zapomenute-heslo/overeni",
    DISCORD_CONNECT: "/pripojit-discord",
    CONFIRM_EMAIL: "/potvrzeni-emailu",
    PAY: "/platba",
    INSTAGRAM: "/instagram",
    DASHBOARD: "/clenska-sekce",
    DASHBOARD_VIDEOS: `/clenska-sekce/videos`,
    DASHBOARD_SUBSCRIBTION: `/clenska-sekce/clenstvi`,
    DASHBOARD_COURSES: `/clenska-sekce/kurzy`,
    DASHBOARD_REFERRAL: `/clenska-sekce/affiliate`,
    DASHBOARD_PROFILE: `/clenska-sekce/profil`,
})

export const DISCORD_URL = Object.freeze({
    CRYPTONYT_DEFAULT_CONNECT: "https://discord.com/channels/1001447138628227132/1001485054104567889",
    CRYPTONY_CAPITAL_CONNECT: "https://discord.com/channels/1148274848670433410/1154330939531464737"
})

export const PAGE_URL = URL;
