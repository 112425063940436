"use client"
import "./LanguagePicker.scss";
import {useLocale} from "next-intl";
import {usePathname, useRouter} from "@/navigation";
import {useTransition} from "react";
interface ILanguage {
  name: string;
  shortcut: string;
}

const LanguagePicker = () => {
  const LANGUAGES = ['cs', "en"];
  const locale = useLocale();
    const router = useRouter();
    const pathname = usePathname();

    const handleSelect = (language: string) => {

        window.location.href = `/${language}`;
    }

  return (
    <div className="language-picker">
        <button>
            <span>{locale == "cs" ? "cz" : locale}</span>
        </button>
        <div className="language-picker-dropdown">
          <ul>
              {LANGUAGES.map(language => {
                  return (
                      <li key={language}>
                          <button onClick={() => handleSelect(language)}>{language === "cs" ? "cz" : language}</button>
                      </li>
                  )
              })}
          </ul>
        </div>
    </div>
  );
};

export default LanguagePicker;
