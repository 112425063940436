import "./Button.scss";
import { Link } from "@/navigation";
import LoadingIcon from "@/components/atom/Icons/LoadingIcon";
interface IProps {
  children: React.ReactNode;
  target?: string;
  link?: string;
  size?: "small" | "medium" | "large";
  className?: string;
  type:
    | "ARROW_BUTTON"
    | "ADMINISTRATION_BUTTON"
    | "NETFLIX"
    | "BORDERED"
    | "DEFAULT";
  isLoading?: boolean;
  onClick?: (e: any) => void;
  isActive?: boolean
}

const Button = ({
  children,
  target,
  link,
  type,
  onClick,
  size,
  className,
  isLoading,
  isActive
}: IProps) => {
  const classNames: Record<string, string> = {
    ARROW_BUTTON: "btn-arrow",
    ADMINISTRATION_BUTTON: "btn-administration",
    NETFLIX: "btn-netflix",
    BORDERED: "btn-bordered",
    DEFAULT: "btn-default",
  };

  const classes = `btn ${classNames[type]} ${size ?? ""} ${className ?? ""} ${isActive ? 'isActive' : ''}`;

  if (link) {
    return (
      <Link href={link} className={classes} target={target}>
        <span>{isLoading ? <LoadingIcon /> : children}</span>
      </Link>
    );
  }

  return (
    <button aria-label={"click"} className={classes} onClick={onClick}>
      <span>{isLoading ? <LoadingIcon /> : children}</span>
    </button>
  );
};

export default Button;
