import Image from "next/image";

import One from "@/assets/images/members/1.png";
import Two from "@/assets/images/members/2.png";
import Three from "@/assets/images/members/3.png";
import Four from "@/assets/images/members/4.png";




import "./Members.scss";
import {useTranslations} from "next-intl";

const Members = () => {

    const t = useTranslations("members");

    const members = [One, Two, Three, Four];

    return (
    <>
      <div className="members">
        <div className="members-wrap">
          {members.map((member, index) => {
            return (
              <div className="members-item" key={index}>
                <Image
                  src={member}
                  width={30}
                  height={30}
                  alt={t("member")}
                />
              </div>
            );
          })}
        </div>
        <p dangerouslySetInnerHTML={{__html: t.raw('title')}}>
        </p>
      </div>
    </>
  );
};

export default Members;
