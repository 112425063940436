import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/animate.css/animate.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/logos/logo-text.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atom/Heading/Heading.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Footer/Footer.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Homepage/Access/Access.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Homepage/Benefits/Benefits.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Homepage/Faq/Faq.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Homepage/Guarantee/Guarantee.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Homepage/HeroSection/HeroSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Homepage/Points/Points.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/organism/Homepage/PriceList/PriceList.tsx")