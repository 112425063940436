"use client"
import {AnimationOnScroll} from "react-animation-on-scroll";
import {useEffect, useState} from "react";
import {useWindowSize} from "@uidotdev/usehooks";

const AnimationOnScrollCustom = ({children}: {children: React.ReactNode}) => {

    const { width } = useWindowSize();
    const IS_DESKTOP = !(width && width < 776);

    if (!IS_DESKTOP) return children;

    return (
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
            {children}
        </AnimationOnScroll>
    )
}

export default AnimationOnScrollCustom;
