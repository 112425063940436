interface IProps {
  IconComponent: (props: any) => React.JSX.Element;
  title: string;
}

const BenefitsItem = ({ IconComponent, title }: IProps) => {
  return (
    <div className="benefits-item">
      <div className="benefits-item-icon">
        <IconComponent />
      </div>
      <div className="benefits-item-text">{title}</div>
    </div>
  );
};

export default BenefitsItem;
