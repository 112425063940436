import * as React from "react";
const LockIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={23}
        fill="none"
        viewBox={"0 0 772 974"}
        {...props}
    >
      <path
          fill="#020000"
          fillRule="evenodd"
          d="M651 454H48v472h676V454h-73Zm0-206v158h85c19.882 0 36 16.118 36 36v496c0 19.882-16.118 36-36 36H36c-19.882 0-36-16.118-36-36V442c0-19.882 16.118-36 36-36h85V248C121 111.033 232.033 0 369 0h34c136.967 0 248 111.033 248 248ZM169 406h434V248c0-110.457-89.543-200-200-200h-34c-110.457 0-200 89.543-200 200v158Zm241 332.828c40.872-10.817 71-48.054 71-92.328 0-52.743-42.757-95.5-95.5-95.5S290 593.757 290 646.5c0 44.634 30.62 82.117 72 92.587V811c0 13.255 10.745 24 24 24s24-10.745 24-24v-72.172ZM385.5 694c26.234 0 47.5-21.266 47.5-47.5S411.734 599 385.5 599 338 620.266 338 646.5s21.266 47.5 47.5 47.5Z"
          clipRule="evenodd"
      />
      <path
          fill="url(#a)"
          fillRule="evenodd"
          d="M651 454H48v472h676V454h-73Zm0-206v158h85c19.882 0 36 16.118 36 36v496c0 19.882-16.118 36-36 36H36c-19.882 0-36-16.118-36-36V442c0-19.882 16.118-36 36-36h85V248C121 111.033 232.033 0 369 0h34c136.967 0 248 111.033 248 248ZM169 406h434V248c0-110.457-89.543-200-200-200h-34c-110.457 0-200 89.543-200 200v158Zm241 332.828c40.872-10.817 71-48.054 71-92.328 0-52.743-42.757-95.5-95.5-95.5S290 593.757 290 646.5c0 44.634 30.62 82.117 72 92.587V811c0 13.255 10.745 24 24 24s24-10.745 24-24v-72.172ZM385.5 694c26.234 0 47.5-21.266 47.5-47.5S411.734 599 385.5 599 338 620.266 338 646.5s21.266 47.5 47.5 47.5Z"
          clipRule="evenodd"
      />
      <defs>
        <linearGradient
            id="a"
            x1={696.369}
            x2={95.686}
            y1={407.063}
            y2={407.063}
            gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E4B4" />
          <stop offset={0.143} stopColor="#00D2BF" />
          <stop offset={0.286} stopColor="#00C0CA" />
          <stop offset={0.429} stopColor="#00AED5" />
          <stop offset={0.571} stopColor="#009CE1" />
          <stop offset={0.714} stopColor="#008BEC" />
          <stop offset={0.857} stopColor="#0079F7" />
          <stop offset={1} stopColor="#006CFF" />
        </linearGradient>
      </defs>
    </svg>
);
export default LockIcon;
