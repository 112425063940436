"use client";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import PriceCard from "@/components/molecule/Cards/PriceCard/PriceCard";
import {
    getPrice,
    MONTH_PRICE_CARD,
    QUARTAL_PRICE_CARD,
    YEAR_PRICE_CARD,
} from "@/utils/data/price";
import { useWindowSize } from "@uidotdev/usehooks";
import {useLocale} from "next-intl";
import {useState} from "react";
import Button from "@/components/atom/Button/Button";

interface IProps {
    actionQuartal?: () => void
    actionYear?: () => void
    actionMonth?: () => void
}

const PriceListCarousel = ({actionQuartal, actionYear, actionMonth}: IProps) => {
  const { width } = useWindowSize();
  const locale = useLocale();

  const [priceCardType, setPriceCardType] = useState<"month" | "quartal" | "year">("quartal");

  return (
    <div className="price-carousel">
        {priceCardType === 'quartal' && <PriceCard action={actionQuartal} disableEffect card_details={getPrice('quartal', locale)} active={false} />}
        {priceCardType === 'month' && <PriceCard action={actionMonth} disableEffect card_details={getPrice('month', locale)} active={false} />}
        {priceCardType === 'year' && <PriceCard action={actionYear} disableEffect card_details={getPrice('year', locale)} active={false} />}

        <div className={'price-carousel-buttons'}>
            <Button type={"DEFAULT"} size={"small"} isActive={"month" === priceCardType} onClick={() => setPriceCardType('month')}>Měsíční</Button>
            <Button type={"DEFAULT"} size={"small"} isActive={"quartal" === priceCardType} onClick={() => setPriceCardType('quartal')}>Kvartální</Button>
            <Button type={"DEFAULT"} size={"small"} isActive={"year" === priceCardType} onClick={() => setPriceCardType('year')}>Roční</Button>
        </div>
    </div>
  );
};

export default PriceListCarousel;
