"use client";
import Heading from "@/components/atom/Heading/Heading";

import "./PriceList.scss";
import PriceCard from "@/components/molecule/Cards/PriceCard/PriceCard";
import {
    getPrice,
    MONTH_PRICE_CARD,
    QUARTAL_PRICE_CARD,
    YEAR_PRICE_CARD,
} from "@/utils/data/price";
import PaymentOptions from "@/components/atom/PaymentOptions/PaymentOptions";
import PriceListCarousel from "./PriceListCarousel";

import { useWindowSize } from "@uidotdev/usehooks";
import {useLocale, useTranslations} from "next-intl";

const PriceList = () => {
  const { width } = useWindowSize();
  const t = useTranslations("price-list");
  const locale = useLocale();
  const IS_MOBILE = width && width <= 991;


  return (
    <div className="price-list">
      <div className="container">
        <Heading
          small={t("subtitle")}
          description={t("description")}
        >
            {t.raw("title")}
        </Heading>
      </div>
      {IS_MOBILE ? (
        <PriceListCarousel />
      ) : (
        <div className="container">
          <div className="price-list-cards">
            <PriceCard card_details={getPrice("month", locale)} active={false} />
            <PriceCard card_details={getPrice("quartal", locale)} active={true} />
            <PriceCard card_details={getPrice("year", locale)} active={false} />
          </div>
        </div>
      )}
      <div className="container">
        <div className="price-list-payment">
          <PaymentOptions />
        </div>
      </div>
    </div>
  );
};

export default PriceList;
