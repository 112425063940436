import React from "react";

function LoadingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={"rotating"}
    >
      <g>
        <path
          stroke="url(#paint0_linear_122_2415)"
          strokeLinecap="round"
          d="M1 8a7 7 0 107-7"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_122_2415"
          x1="14.717"
          x2="0.979"
          y1="13.25"
          y2="13.131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E4B4"></stop>
          <stop offset="1" stopColor="#085CFF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LoadingIcon;
