import { IFaq } from "@/types/faq";

export const faqData: IFaq[] = [
    {
        id: 0,
        title: "Můžu se přidat, když mi nebylo 18?",
        description: "Ano můžete. Čím dříve se začnete o financích vzdělávat, tím lépe. Pokud jste mladí, tak určitě stojí za to přečíst si naši sekci o finanční gramotnosti. Vyhnete se tak obrovskému potenciálnímu problému."
    },
    {
        id: 1,
        title: "Co dělat, když něco nefunguje tak, jak má?",
        description: "Napište nám na Instagram: cryptonyt.cz, tam se s vámi, co nejdříve spojí náš tým a celou situaci vám pomůže vyřešit."
    },
    {
        id: 2,
        title: "Jak poznám, že je Cryptonyt pro mě?",
        description: "To nepoznáte, dokud se nepřidáte. Nicméně se nám ještě nestalo, že by nám někdo po přidání napsal, že se nic nového nenaučil."
    },
    {
        id: 3,
        title: "Proč bych měl studovat peníze?",
        description: "Pokud jste se nenarodili do bohaté rodiny, tak budete pro peníze pracovat celý život. No a když už tohle víme, nebylo by fajn jim rozumět a udržet si jejich kupní sílu? My věříme, že určitě ANO!"
    },
    {
        id: 4,
        title: "Jak vás můžu podpořit?",
        description: "Nejvíce nás podpoříte tím, že o nás řeknete známým a rodině. Zároveň k tomu můžete využít náš Affiliate program, ve kterém si můžete přivydělat tím, že naší službu budou využívat vaši známí."
    }
];

export const faqDataEn: IFaq[] = [
    {
        id: 0,
        title: "Can I join if I wasn't 18?",
        description: "Yes, you can. The sooner you start educating yourself about finance, the better. If you're young, it's definitely worth reading our section on financial literacy. This will avoid a huge potential problem."
    },
    {
        id: 1,
        title: "What to do if something doesn't work as it should?",
        description: "Write to us on Instagram: cryptonyt.cz, our team will get in touch with you as soon as possible and help you resolve the situation."
    },
    {
        id: 2,
        title: "How do I know if Cryptonyt is for me?",
        description: "You won't know until you join. However, it has not yet happened to us that someone wrote to us that they did not learn anything new."
    },
    {
        id: 3,
        title: "Why should I study money?",
        description: "Unless you were born into a wealthy family, you will work for money all your life. And now that we know this, wouldn't it be nice to understand them and maintain their purchasing power? We believe that it definitely DOES!"
    },
    {
        id: 4,
        title: "How can I support you?",
        description: "You can support us the most by telling your friends and family about us. At the same time, you can use our Share to Earn program, in which you can earn extra money by using our service by your friends."
    }
];
