"use client"
import {ACCESS_DATA, ACCESS_DATA_EN} from "@/utils/data/access";
import AccessItem from "./AccessItem";

import "./Access.scss";
import Heading from "@/components/atom/Heading/Heading";
import {useLocale, useTranslations} from "next-intl";

const Access = () => {

    const t = useTranslations("access");
    const locale = useLocale();

    const data = locale === 'cs' ? ACCESS_DATA : ACCESS_DATA_EN

  return (
    <div className="access">
      <div className="container">
        <Heading>
            {t.raw("title")}
        </Heading>
        <div className="access-content">
          {data.map((accessItem, i) => (
            <AccessItem key={i} {...accessItem} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Access;
