"use client";
import Image from "next/image";
import "./Points.scss";
import PointsLine from "./PointsLine";
import Heading from "@/components/atom/Heading/Heading";
import { AnimationOnScroll } from "react-animation-on-scroll";

import One from "@/assets/images/points/pristup-k-exluzivnim-informacim.webp";
import Two from "@/assets/images/points/slozita-temata.webp";
import Three from "@/assets/images/points/pripravime-vas-na-budoucnost.webp";
import {useTranslations} from "next-intl";
import AnimationOnScrollCustom from "@/components/atom/AnimationOnScroll/AnimationOnScroll";

const Points = () => {

  const t = useTranslations("points");

  return (
    <div className="points">
      <div className="container">
        <Heading smallBottom={t("subtitle")}>
          {t.raw('title')}
        </Heading>
        <div className="points-content">
          <AnimationOnScrollCustom>
            <div className="points-item">
              <div className="points-item-text">
                <h3 dangerouslySetInnerHTML={{ __html: t.raw("one.title") }}>
                </h3>
                <p>
                  {t("one.description")}
                </p>
              </div>
              <div className="points-item-photo">
                <div className={"points-item-photo-content"}>
                  <Image
                      width={450}
                      height={300}
                      src={One}
                      alt={t.raw("one.title")}
                  />
                </div>
              </div>
            </div>
          </AnimationOnScrollCustom>
          <AnimationOnScrollCustom>
            <div className="points-item">
              <div className="points-item-photo">
                <div className={"points-item-photo-content"}>
                  <Image
                      width={450}
                      height={300}
                      src={Two}
                      alt={t.raw("two.title")}
                  />
                </div>
              </div>
              <div className="points-item-text">
                <h3 dangerouslySetInnerHTML={{ __html: t.raw("two.title") }}>
                </h3>
                <p>
                  {t("two.description")}
                </p>
              </div>
            </div>
          </AnimationOnScrollCustom>
          <AnimationOnScrollCustom>
            <div className="points-item">
              <div className="points-item-text">
                <h3 dangerouslySetInnerHTML={{ __html: t.raw("three.title") }}>
                </h3>
                <p>
                  {t("three.description")}
                </p>
              </div>
              <div className="points-item-photo">
                <div className={"points-item-photo-content"}>
                  <Image
                      width={450}
                      height={300}
                      src={Three}
                      alt={t.raw("three.title")}
                  />
                </div>
              </div>
            </div>
          </AnimationOnScrollCustom>
          <PointsLine />
        </div>
      </div>
    </div>
  );
};

export default Points;
