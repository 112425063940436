import { IFaq, IFaqExtended } from "@/types/faq";

import "./Faq.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import AnimationOnScrollCustom from "@/components/atom/AnimationOnScroll/AnimationOnScroll";

interface IProps {
  faq: IFaq;
  activeIds: number[];
  setActiveId: (id_faq: number) => void;
}

export const FaqItem = ({ faq, activeIds, setActiveId }: IProps) => {
  return (
    <AnimationOnScrollCustom>
      <div className="faq-item">
        <div className="faq-item-inner">
          <div className="faq-item-title" onClick={() => setActiveId(faq.id)}>
            <span>{faq.title}</span>
            <button className={`${activeIds.includes(faq.id) ? "active" : ""}`}>
              <span></span>
              <span></span>
            </button>
          </div>
          <div
            className={`faq-item-description ${
              activeIds.includes(faq.id) ? "active" : ""
            }`}
          >
            {faq.description}
          </div>
        </div>
      </div>
    </AnimationOnScrollCustom>
  );
};

export default FaqItem;
