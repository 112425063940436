"use client";
import { IAccess } from "@/types/access";
import Image from "next/image";
import PlusIcon from "@/components/atom/Icons/PlusIcon";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { classNames } from "primereact/utils";
import ClockIcon from "@/components/atom/Icons/ClockIcon";
import {useTranslations} from "next-intl";
import VerifiedIcon from "@/components/atom/Icons/VerifiedIcon";
import AnimationOnScrollCustom from "@/components/atom/AnimationOnScroll/AnimationOnScroll";

const AccessItem = (props: IAccess) => {
  const { type, title, text, url } = props;

  const t = useTranslations("access");

  const className = classNames({
    "access-item": true,
    "access-item-background": type,
  });
  return (
    <>
      {type && type === 2 && (
        <div className="access-item-gap">
          <>
            <PlusIcon />
            <h3 className="access-item-gap-title">
              <span>{t("bonusFreeSpan")}</span>
              {/*<span className="access-item-gap-title-large">2600,-</span>*/}
            </h3>
          </>
        </div>
      )}
      <div className={`access-item-box`}>
        <div className={className}>
          <div className={`access-item-text`}>
            <AnimationOnScrollCustom>
              <h3>
                {title}
                <VerifiedIcon height={30} width={30} />
              </h3>
            </AnimationOnScrollCustom>
            <AnimationOnScrollCustom>
              <p>{text}</p>
            </AnimationOnScrollCustom>
            {type === 1 && (
              <AnimationOnScrollCustom>
                <div className="access-item-specialOffer">
                  {/*<span className="access-item-priceBefore mr-3">2600,-</span>
                  <span className="access-item-highlightedText">
                    {t("free")}
                  </span>*/}
                </div>
              </AnimationOnScrollCustom>
            )}
            {(type === 2 || type === 3) && (
              <AnimationOnScrollCustom>
                <div className="access-item-specialOffer">
                  <span className="access-item-highlightedText mr-2">
                    {t("limitedOffer")}
                  </span>
                  <ClockIcon />
                </div>
              </AnimationOnScrollCustom>
            )}
          </div>
          <div className="access-item-image">
            <AnimationOnScrollCustom>
              <Image width={1020} height={574} alt={title} src={url} />
            </AnimationOnScrollCustom>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessItem;
