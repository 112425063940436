import { IPrice } from "@/types/price";
import {useTranslations} from "next-intl";


function getPoints(isCzech: boolean)  {
    return [
        {
            text:  isCzech ? "Pravidelné Výhledy Trhu" : "Regular Market Outlooks",
            icon: "complete",
        },
        {
            text:  isCzech ? "Rozbory Finančních Krizí" : "Analyzes of Financial Crises",
            icon: "complete",
        },
        {
            text:  isCzech ? "Budoucnost CBDC's" : "The future of CBDC's",
            icon: "complete",
        },
        {
            text:  isCzech ? "Learn to Earn Kategorie" : "Learn to Earn Category",
            icon: "complete",
        },
        {
            text:  isCzech ? "Makroekonomické Rozbory" : "Macroeconomic Analysis",
            icon: "complete",
        },
        {
            text:  isCzech ? "CRYPTONYT CAPITAL" : "CRYPTONYT CAPITAL",
            icon: "present",
        },
        {
            text:  isCzech ? "CRYPTONYT BUSINESS" : "CRYPTONYT BUSINESS",
            icon: "present",
        },
        {
            text:  isCzech ? "CLOSE FRIENDS" : "CLOSE FRIENDS",
            icon: "present",
        }
    ]
}
export function getPrice(type: "year" | "quartal" | "month",locale: string): IPrice {

    const isCzech = locale === 'cs'

    if (type === "year") {
        return {
            title: isCzech ? "Roční členství" : "Annual membership",
            description: isCzech ? "platba 5280 Kč každý rok" : "payment of CZK 5280 every year",
            price: 440,
            duration: isCzech ? "za měsíc" : "per month",
            points: getPoints(isCzech)
        }
    }
    if (type === "month") {
        return {
            title: isCzech ? "Měsíční členství" : "Monthly membership",
            description: isCzech ? "platba 800 Kč každý měsíc" : "payment of CZK 800 every month",
            price: 800,
            duration: isCzech ? "za měsíc" : "per month",
            points: getPoints(isCzech)

        }
    }

    return {
        title: isCzech ? "Kvartální členství" : "Quarterly membership",
        description: isCzech ? "platba 1590 Kč každý 3. měsíc" : "payment of CZK 1,590 every 3 months",
        price: 530,
        duration: isCzech ? "za měsíc" : "per month",
        points: getPoints(isCzech)

    }

}

export const YEAR_PRICE_CARD: IPrice = {
    title: "Roční členství",
    description: "platba 4656 Kč každý rok",
    price: 388,
    duration: "za měsíc",
    points: [
        {
            text: "Výhledy trhu",
            icon: "complete",
        },
        {
            text: "Finační krize",
            icon: "complete",
        },
        {
            text: "Learn to Earn",
            icon: "complete",
        },
        {
            text: "budoucnost CBDCs",
            icon: "complete",
        },
        {
            text: "CLOSE FRIENDS",
            icon: "present",
        },
        {
            text: "CRYPTONYT BUSINESS",
            icon: "present",
        }
    ]
}
export const QUARTAL_PRICE_CARD: IPrice = {
    title: "Kvartální členství",
    description: "platba 1320 Kč každý 3. měsíc",
    price: 440,
    duration: "za měsíc",
    points: [
        {
            text: "Výhledy trhu",
            icon: "complete",
        },
        {
            text: "Finační krize",
            icon: "complete",
        },
        {
            text: "Learn to Earn",
            icon: "complete",
        },
        {
            text: "budoucnost CBDCs",
            icon: "complete",
        },
        {
            text: "CLOSE FRIENDS",
            icon: "present",
        },
        {
            text: "CRYPTONYT BUSINESS",
            icon: "present",
        }
    ]
}

export const MONTH_PRICE_CARD: IPrice = {
    title: "Měsíční členství",
    description: "platba 628 Kč každý měsíc",
    price: 628,
    duration: "za měsíc",
    points: [
        {
            text: "Výhledy trhu",
            icon: "complete",
        },
        {
            text: "Finační krize",
            icon: "complete",
        },
        {
            text: "Learn to Earn",
            icon: "complete",
        },
        {
            text: "budoucnost CBDCs",
            icon: "complete",
        },
        {
            text: "CLOSE FRIENDS",
            icon: "present",
        },
        {
            text: "CRYPTONYT BUSINESS",
            icon: "present",
        }
    ]
}
