"use client";

import Heading from "@/components/atom/Heading/Heading";
import { IFaq, IFaqExtended } from "@/types/faq";
import {faqData, faqDataEn} from "@/utils/data/faq";
import React, { useEffect, useState } from "react";
import FaqItem from "./FaqItem";

import "./Faq.scss";
import {useLocale, useTranslations} from "next-intl";

const Faq = () => {
  const [activeIds, setActiveIds] = useState<number[]>([0]);
  const t = useTranslations("faq");
  const locale = useLocale();
  const setActive = (id_faq: number) => {
    if (activeIds?.includes(id_faq)) {
      setActiveIds(activeIds.filter((activeId) => activeId !== id_faq));
      return;
    }
    setActiveIds([...activeIds, id_faq]);
  };

  const data = locale === 'cs' ? faqData : faqDataEn

  return (
    <div className="faq">
      <div className="container">
        <Heading description={t("description")}>
          {t.raw("title")}
        </Heading>
        <div className="faq-content">
          {data.map((faqItem) => (
            <FaqItem
              key={faqItem.id}
              activeIds={activeIds}
              faq={faqItem}
              setActiveId={(id_faq: number) => setActive(id_faq)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
