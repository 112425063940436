"use client";
import "./Heading.scss";

import { AnimationOnScroll } from "react-animation-on-scroll";
import AnimationOnScrollCustom from "@/components/atom/AnimationOnScroll/AnimationOnScroll";

interface IPropsStyle {
  descriptionFull?: boolean;
}
interface IProps {
  children: React.ReactNode;
  small?: string;
  description?: string;
  style?: IPropsStyle;
  smallBottom?: string;
}

const Heading = ({
  children,
  small,
  description,
  style,
  smallBottom,
}: IProps) => {

  const isString = typeof children === 'string';

  return (
    <AnimationOnScrollCustom>
      <div className="heading">
        {small && <span>{small}</span>}
        {isString ?
            <h2 dangerouslySetInnerHTML={{ __html: children }}></h2>
            :
            <h2>{children}</h2>
        }
        {description && (
          <p className={style?.descriptionFull ? `mw-full` : ""}>
            {description}
          </p>
        )}
        {smallBottom && <span className="heading-bottom">{smallBottom}</span>}
      </div>
    </AnimationOnScrollCustom>
  );
};

export default Heading;
